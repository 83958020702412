<template>
    <div class="guide-list">
        <guide-list-empty
            v-if="showDAEmptyState"
            :loading="isFetchingGuides"
            @showGuideCreateChange="$emit('showGuideCreateChange', $event)" />
        <div v-if="!showDAEmptyState">
            <global-filters :show-mobile-apps="activeIsTrainingSubscription">
                <template #right>
                    <search
                        :value="searchInput"
                        placeholder="Search"
                        @input="updateSearch" />
                </template>
                <template #expansion>
                    <guide-list-expansion-filters
                        :active-filters="activeFilters"
                        :show-mobile-apps="activeIsTrainingSubscription" />
                </template>
            </global-filters>
            <pendo-table
                ref="guideListTable"
                :data="filteredList"
                :filters="tableFilters"
                :columns="columns"
                :default-sort="sort"
                :loading="isFetchingGuides"
                :row-class-name="getRowClassName"
                manage-columns
                csv-download
                auto-height
                title="Guides"
                row-key="id"
                class="guide-list-table"
                resizable
                :checkbox-config="{ checkMethod: canSelectGuide }"
                @select-change="onSelectRowChange"
                @select-clear="onSelectRowClear"
                @column-change="handleColumnChange"
                @column-resize="handleColumnResize"
                @sort-change="handleSortChange">
                <template #headerLeft>
                    <div class="guide-list-table--table-header-left">
                        <div class="guide-list-table--table-title">
                            Guides
                        </div>
                        <div class="guide-list-table--active-count">
                            ({{ selectedAndTotalGuidesCount() }})
                        </div>
                        <bulk-actions-header :selected-row-count="selectedTableRows.length">
                            <template #actions>
                                <pendo-button
                                    v-if="canCopyGuidesAcrossSubs && !hasCrossAppSelected && canCopyAllSelectedGuides"
                                    data-cy="guide-list--copy-guides"
                                    theme="app"
                                    type="link"
                                    prefix-icon="repeat"
                                    label="Copy to Subscription"
                                    @click="openGuideCopyDrawer" />
                                <pendo-button
                                    v-if="showCreateCrossAppGuideButton"
                                    data-cy="guide-list--create-cross-app-guides"
                                    theme="app"
                                    type="link"
                                    prefix-icon="merge"
                                    label="Create Cross-App Guide"
                                    @click="openCrossAppGuideDrawer" />
                            </template>
                        </bulk-actions-header>
                    </div>
                </template>
                <template #name="{ row }">
                    <router-link
                        :to="getGuideLink(row)"
                        class="guide-list-table--link">
                        {{ row.name }}
                    </router-link>
                </template>
                <template #apps="{ row }">
                    <pendo-app-display
                        :apps="row.apps"
                        :show-cross-app-display="row.isMultiApp" />
                </template>
                <template #state="{ row }">
                    <guide-status-changer
                        :guide="row"
                        :guide-type="type"
                        :hide-staged-option="isStagedOptionHidden || !!isCrossApp(row)"
                        size="mini"
                        @select="setGuideStatusUpdateData" />
                </template>
                <template #editorType="{ row }">
                    <div class="editor-type-cell">
                        <pendo-icon
                            size="14"
                            :type="editorTypeToHuman(row) === 'Adopt Studio' ? 'adopt-studio' : 'visual-design-studio'" />
                        {{ editorTypeToHuman(row) }}
                    </div>
                </template>
                <template #actions="{ row }">
                    <guide-utilities
                        :is-dropdown="false"
                        :guide="row"
                        @openGuideCopyDrawer="openGuideCopyDrawerWithSingleGuide"
                        @openLaunchPreviewModal="openLaunchPreviewModal"
                        @openDeleteGuideModal="openDeleteGuideModal" />
                </template>
                <template #numViewsHeader>
                    <div class="guide-views-column-header">
                        <span class="guide-views-column-header--text">Guide Views</span>
                        <pendo-icon
                            v-if="hasCrossAppGuides"
                            v-pendo-tooltip="guideViewsTooltip"
                            class="guide-views-column-header--icon"
                            stroke="#128297"
                            size="14"
                            type="info" />
                    </div>
                </template>
                <template #empty>
                    <div class="guide-list-table--empty">
                        <pendo-empty-state
                            title="Guides not found"
                            :description="emptyText">
                            <template #icon>
                                <guide-list-table-empty-state />
                            </template>
                        </pendo-empty-state>
                    </div>
                </template>
            </pendo-table>
            <bulk-guide-copy-drawer
                ref="bulkCopyDrawer"
                :visible="showBulkCopyDrawer"
                :guides="guidesToCopy"
                :eligible-subscriptions="eligibleSubscriptions"
                :loading-source-countables="loadingSourceCountables"
                :active-is-digital-adoption="activeIsDigitalAdoption"
                :active-is-training-subscription="activeIsTrainingSubscription"
                @open="onBulkCopyOpen"
                @close="closeBulkCopyDrawer"
                @closed="onBulkCopyClose" />
            <cross-app-guide-drawer
                :visible="showCrossGuideCreateDrawer"
                :has-cross-app-enabled="hasCrossAppEnabled"
                :selected-guides="selectedTableRows"
                :all-guides="list"
                :apps-map="appsMap"
                :app-urls-map="appUrlsMap"
                :support-article-url="crossAppSupportArticleUrl"
                :error-on-create="errorOnCreateCrossAppGuide"
                :error-on-update-guide-statuses="errorOnUpdateGuideStatusesForCrossApp"
                :active-sub-id="activeSubId"
                :is-admin="isAdmin"
                @create="createCrossAppGuide"
                @close="closeCrossAppGuideDrawer" />
            <guide-status-change-confirmation-modal
                v-if="guidestatusupdate.guide && guidestatusupdate.status != ''"
                :guide="guidestatusupdate.guide"
                :status="guidestatusupdate.status"
                @cancel="clearGuideStatusUpdateData"
                @submit="updateGuideStatus" />
            <launch-preview-modal
                :visible="showLaunchPreviewModal"
                v-bind="launchPreviewModalProps"
                @close="closeLaunchPreviewModal" />
            <delete-guide-modal
                :visible="showDeleteGuideModal"
                v-bind="deleteGuideModalProps"
                @close="closeDeleteGuideModal" />
        </div>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import { EDITOR_TYPES } from '@pendo/services/Constants';
import { mapMutations, mapActions, mapGetters, mapState } from 'vuex';
import { filterBarChangeSubscriber } from '@/state/modules/filters.module';
import {
    PendoAppDisplay,
    PendoIcon,
    PendoTable,
    PendoButton,
    PendoTooltip,
    PendoLoading,
    PendoEmptyState
} from '@pendo/components';
import Search from '@/components/Search';
import GuideStatusChangeConfirmationModal from '@/components/guides/common/GuideStatusChangeConfirmationModal';
import GuideStatusChanger from '@/components/guides/common/GuideStatusChanger';
import GuideUtilities from '@/components/guides/common/GuideUtilities';
import GlobalFilters from '@/components/filters/GlobalFilters';
import GuideListEmpty from '@/components/guides/list/GuideListEmpty';
import BulkGuideCopyDrawer from '@/components/guides/list/bulk-guide-copy/BulkGuideCopyDrawer';
import GuideListExpansionFilters from '@/components/guides/list/GuideListExpansionFilters';
import CrossAppGuideDrawer from '@/stateless-components/guides/cross-app/CrossAppGuideDrawer';
import BulkActionsHeader from '@/components/common/BulkActionsHeader';
import GuideListTableEmptyState from '@/components/svgs/GuideListTableEmptyState';
import LaunchPreviewModal from '@/stateless-components/modals/LaunchPreviewModal.vue';
import DeleteGuideModal from '@/components/DeleteGuideModal.vue';
import { getInlineSegmentSummary, getMissingEntityText } from '@pendo/segment-builder';
import { commaSeparatedAppList, filterEntitiesByAppIds, filterEntitiesByAppKeys } from '@/utils/apps';
import {
    createCrossAppGuide,
    hasGuideWithZeroSteps,
    isBadgeGuide,
    isConfirmationGuide,
    isCrossAppGuideEligible,
    isEditedByAdopt,
    updateGuideStatusesForCrossApp
} from '@/stateless-components/utils/guides';
import { createEmptyGuide } from '@/utils/guides';
import { formatGuideState, getGuideLink } from '@/stateless-components/utils/guides';
import { CROSS_APP_SUPPORT_ARTICLE_URL } from '@/constants/urls';
import { isCrossApp } from '@pendo/services/CrossAppGuides';
import moment, { DATE_FORMAT } from '@/utils/moment';
import {
    mergeDefaultSortWithUserSettings,
    mergeDefaultColumnsWithUserSettings,
    mergeDefaultFiltersWithUserSettings
} from '@/stateless-components/utils/table-user-settings';
import { loadSourceCountables, eligibleSubscriptions } from '@/utils/copy-guides';
import { canCreateGuide, canCopyGuide, canCreateFromScratch } from '@/utils/guide-permissions';
import { isMobile } from '@/stateless-components/utils/apps';
import {
    onColumnResize,
    onColumnChange,
    onSortChange
} from '@/stateless-components/utils/pendo-table-user-settings-helper.js';

const TOOLTIP_CONFIG = {
    multiline: true,
    trigger: 'hover',
    placement: 'top',
    arrow: true
};

export default {
    name: 'GuideListTable',
    components: {
        PendoAppDisplay,
        PendoIcon,
        PendoTable,
        PendoButton,
        PendoEmptyState,
        GuideListEmpty,
        GuideListTableEmptyState,
        GuideStatusChanger,
        GuideUtilities,
        GlobalFilters,
        Search,
        BulkGuideCopyDrawer,
        BulkActionsHeader,
        CrossAppGuideDrawer,
        GuideListExpansionFilters,
        GuideStatusChangeConfirmationModal,
        LaunchPreviewModal,
        DeleteGuideModal
    },
    directives: {
        PendoTooltip,
        PendoLoading
    },
    props: {
        // eslint-disable-next-line vue/require-default-prop
        type: {
            type: String,
            validator: (type) => ['partner', 'organization'].includes(type)
        }
    },
    emits: ['showGuideCreateChange'],
    data () {
        return {
            guidesListUserSettingsName: 'guide-list',
            guideTableExpansionFiltersSettingsName: 'guide-table-expansion-filters',
            crossAppSupportArticleUrl: CROSS_APP_SUPPORT_ARTICLE_URL,
            searchInput: '',
            selectedTableRows: [],
            unsubscribeFilterBarListener: null,
            showBulkCopyDrawer: false,
            showCrossGuideCreateDrawer: false,
            useSingleGuideCopy: false,
            guide: null,
            defaultSort: { prop: 'name', order: 'ascending' },
            TOOLTIP_CONFIG,
            loadingSourceCountables: false,
            GuideStatusConfirmationModal: null,
            guidestatusupdate: {
                guide: null,
                status: ''
            },
            errorOnUpdateGuideStatusesForCrossApp: null,
            errorOnCreateCrossAppGuide: null,
            showLaunchPreviewModal: false,
            launchPreviewModalProps: {},
            showDeleteGuideModal: false,
            deleteGuideModalProps: {}
        };
    },
    computed: {
        ...mapState({
            appUrlsMap: (state) => state.designer.lastAppUrlMap,
            segmentsMap: (state) => state.filters.segmentsMap,
            guideMap: (state) => state.guides.map,
            isFetchingGuides: (state) => state.guides.isFetching,
            pageMap: (state) => state.pages.map,
            featureMap: (state) => state.features.map,
            appIdsFilter: (state) => state.filters.appIdsFilter,
            workflowMap: (state) => state.workflows.map,
            isFetchingWorkflows: (state) => state.workflows.isFetching,
            activeSubId: (state) => state.subscriptions.activeId
        }),
        ...mapGetters({
            list: 'guides/list',
            maxPriority: 'guides/maxPriority',
            canCopyGuidesAcrossSubs: 'subscriptions/canCopyGuidesAcrossSubs',
            canCreateCrossAppGuide: 'guides/canCreateCrossAppGuide',
            activeApp: 'apps/active',
            apps: 'apps/listAllForActiveSubscription',
            activeIsTrainingSubscription: 'subscriptions/activeIsTrainingSubscription',
            activeUsesDADesigner: 'subscriptions/activeUsesDADesigner',
            activeIsDigitalAdoption: 'subscriptions/activeIsDigitalAdoption',
            hasProductLineMetadataEnabled: 'subscriptions/hasProductLineMetadataEnabled',
            getTableUserSettingValueByName: 'userSettings/getTableUserSettingValueByName',
            appFromGuide: 'apps/appFromGuide',
            isAdmin: 'auth/isAdmin'
        }),
        hasCrossAppEnabled () {
            return this.hasProductLineMetadataEnabled('guides', 'crossAppGuidesInOneUIEnabled');
        },
        appsMap () {
            return keyBy(this.apps, 'id');
        },
        isStagedOptionHidden () {
            return !this.activeIsDigitalAdoption;
        },
        eligibleSubscriptions () {
            return eligibleSubscriptions();
        },
        guidesToCopy () {
            const singleGuide = [this.guide];

            return this.useSingleGuideCopy ? singleGuide : this.selectedTableRows;
        },
        nonCrossAppGuides () {
            return this.list.filter((guide) => !isCrossApp(guide));
        },
        hasMultipleNonCrossAppGuidesSelected () {
            const hasAtLeastOneGuideSelected = this.selectedTableRows.filter((guide) => !isCrossApp(guide)).length;
            const hasCrossAppsSelected = this.hasCrossAppSelected;
            const hasGuidesWithNoStepsSelected = hasGuideWithZeroSteps({ guides: this.selectedTableRows });

            return (
                !!hasAtLeastOneGuideSelected &&
                !hasCrossAppsSelected &&
                !hasGuidesWithNoStepsSelected &&
                !this.hasNonASGuidesSelected &&
                !this.hasBadgeGuideSelected
            );
        },
        hasCrossAppSelected () {
            return !!this.selectedTableRows.filter((guide) => isCrossApp(guide)).length;
        },
        hasBadgeGuideSelected () {
            return !!this.selectedTableRows.filter((guide) => isBadgeGuide(guide)).length;
        },
        hasNonASGuidesSelected () {
            return !!this.selectedTableRows.filter((guide) => !isEditedByAdopt(guide)).length;
        },
        canCopyAllSelectedGuides () {
            return this.selectedTableRows.every((guide) => canCopyGuide(guide));
        },
        canCreateWithAllSelectedGuides () {
            return this.selectedTableRows.every((guide) => canCreateFromScratch(guide.appId));
        },
        canUseCrossAppGuide () {
            return this.activeIsDigitalAdoption && this.canCreateCrossAppGuide;
        },
        showCreateCrossAppGuideButton () {
            return (
                this.canUseCrossAppGuide &&
                this.hasMultipleNonCrossAppGuidesSelected &&
                this.canCreateWithAllSelectedGuides
            );
        },
        columns () {
            const defaultColumns = [
                {
                    prop: 'name',
                    label: 'Guide Name',
                    sortable: true,
                    visible: true
                },
                {
                    prop: 'apps',
                    formatter: (row) => this.getApplicationNameFormatted(row), // Formatter is still necessary for Application column for proper CSV export
                    label: 'Application',
                    sortable: true,
                    minWidth: 130,
                    visible: true
                },
                {
                    prop: 'state',
                    label: 'Status',
                    formatter: (row) => formatGuideState(row),
                    sortable: true,
                    minWidth: 135,
                    visible: true
                },
                {
                    prop: 'segment',
                    label: 'Targeted Segment',
                    formatter: (row) => {
                        if (row.segmentSummary) return row.segmentSummary;

                        const summary = this.segmentSummary(row);
                        const hasError = summary && summary.startsWith('Error');

                        if (hasError && this.loadingSegmentDependencies) return 'Loading segment...';

                        row.segmentSummary = summary;

                        return row.segmentSummary;
                    },
                    showOverflow: 'clamp',
                    visible: true
                },
                {
                    prop: 'lastUpdatedByUser.username',
                    label: 'Last Updated By',
                    sortable: true,
                    visible: true
                },
                {
                    prop: 'lastUpdatedAt',
                    label: 'Last Updated Date',
                    formatter: (row) => moment(row.lastUpdatedAt).format(DATE_FORMAT.full),
                    sortable: true,
                    visible: true
                },
                {
                    prop: 'createdByUser.username',
                    label: 'Created By',
                    sortable: true,
                    visible: true
                },
                {
                    prop: 'launchMethod',
                    label: 'Activation',
                    formatter: (row) => this.activationToHuman(row),
                    sortable: true,
                    visible: true
                },
                {
                    prop: 'steps.length',
                    label: '# of Steps',
                    sortable: true,
                    visible: true
                },
                {
                    prop: 'numViews',
                    label: 'Guide Views',
                    sortable: true,
                    async: true,
                    visible: true
                },
                {
                    prop: 'expiresAfter',
                    label: 'Expiration Date / Time',
                    formatter: (row) =>
                        (row.expiresAfter ? moment(row.expiresAfter).format(DATE_FORMAT.full) : 'Never'),
                    sortable: true,
                    sortBy: (row) => row.expirationDate,
                    visible: true
                },
                {
                    type: 'actions',
                    width: 120,
                    allowResize: false,
                    visible: true
                }
            ];

            if (this.appHasTwoDesigners) {
                const index = defaultColumns.findIndex((col) => col.label === 'Activation');
                defaultColumns.splice(index, 0, {
                    prop: 'editorType',
                    label: 'Edited With',
                    sortable: true,
                    visible: true
                });
            }

            const columns = mergeDefaultColumnsWithUserSettings(defaultColumns, this.guidesListUserSettings);
            if (this.canCopyGuidesAcrossSubs || this.canUseCrossAppGuide) {
                columns.unshift({
                    type: 'checkbox',
                    fixed: true,
                    allowResize: false,
                    visible: true,
                    tooltipFormatter: (row) => {
                        if (!this.canSelectGuide(row)) {
                            return 'Bulk actions are disabled for this guide';
                        }

                        return null;
                    }
                });
            }

            return columns;
        },
        loadingSegmentDependencies () {
            return this.isFetchingGuides || this.isFetchingWorkflows;
        },
        filters () {
            const filters = [
                {
                    prop: [
                        'name',
                        'state',
                        'lastUpdatedByUser.username',
                        'createdByUser.username',
                        'launchMethod',
                        'app.displayName'
                    ],
                    type: 'includes',
                    value: this.searchInput
                },
                {
                    prop: 'launchMethod',
                    type: 'activations',
                    value: []
                },
                {
                    prop: 'state',
                    type: 'statuses',
                    value: []
                },
                {
                    type: 'expirations',
                    value: []
                },
                {
                    prop: 'createdByUser.username',
                    type: 'creators',
                    value: []
                }
            ];

            return mergeDefaultFiltersWithUserSettings(filters, this.guideTableExpansionFiltersUserSettings);
        },
        tableFilters () {
            const currentFilters = cloneDeep(this.filters);
            const expirationsFilter = currentFilters.find((filter) => filter.type === 'expirations');
            const isExpired = (guide) => guide.expiresAfter && moment(guide.expiresAfter) < moment();
            if (expirationsFilter && expirationsFilter.value.length === 1) {
                if (expirationsFilter.value[0] === 'Active') {
                    expirationsFilter.filterFn = (guide) => !isExpired(guide);
                } else if (expirationsFilter.value[0] === 'Expired') {
                    expirationsFilter.filterFn = isExpired;
                }
            } else {
                expirationsFilter.value = [];
            }

            const creatorFilter = currentFilters.find((filter) => filter.type === 'creators');
            creatorFilter.value = creatorFilter.value.map((creator) => creator.id || creator);

            return currentFilters;
        },
        guideListTableData () {
            return cloneDeep(this.list);
        },
        filteredList () {
            const { guideListTableData: allGuides, appIdsFilter, canUseCrossAppGuide, nonCrossAppGuides } = this;
            const guideList = canUseCrossAppGuide ? allGuides : nonCrossAppGuides;

            return canUseCrossAppGuide
                ? filterEntitiesByAppKeys(guideList, appIdsFilter)
                : filterEntitiesByAppIds(guideList, appIdsFilter);
        },
        emptyText () {
            const noResultsText = this.list.length
                ? 'Try changing filters or selecting different applications.'
                : "You haven't created any guides yet.";

            return this.type === 'organization' ? noResultsText : 'There are no public training guides available.';
        },
        canCreateGuide () {
            return canCreateGuide(this.apps);
        },
        showDAEmptyState () {
            if (this.isFetchingGuides) return false;
            if (!this.activeUsesDADesigner) return false;
            if (!this.canCreateGuide) return false;

            return !this.list.length;
        },
        appHasTwoDesigners () {
            if (!this.activeIsDigitalAdoption) {
                return false;
            }

            let hasTwoDesigners = false;
            let isAdoptStudio = false;
            let isVDS = false;

            hasTwoDesigners = this.filteredList.some((guide) => {
                if (isEditedByAdopt(guide)) {
                    isAdoptStudio = true;
                }
                if (guide.editorType === EDITOR_TYPES.ENGAGE_UI || guide.editorType === EDITOR_TYPES.VDS) {
                    isVDS = true;
                }

                return isAdoptStudio && isVDS;
            });

            return hasTwoDesigners;
        },
        hasCrossAppGuides () {
            return this.filteredList.some((guide) => isCrossApp(guide));
        },
        guideViewsTooltip () {
            return {
                ...TOOLTIP_CONFIG,
                content:
                    'The Guide Views column displays the total number of times a Guide has been viewed. For cross-app Guides, the total number of views is broken down by app. If all the apps in a cross app guide are selected, Guide Views displays the aggregated number.'
            };
        },
        sort () {
            return mergeDefaultSortWithUserSettings(this.defaultSort, this.guidesListUserSettings);
        },
        guidesListUserSettings () {
            return this.getTableUserSettingValueByName(this.guidesListUserSettingsName);
        },
        guideTableExpansionFiltersUserSettings () {
            return this.getTableUserSettingValueByName(this.guideTableExpansionFiltersSettingsName);
        },
        activeFilters () {
            return this.filters.slice(1);
        }
    },
    created () {
        this.fetchGuideViews();
        this.fetchGuides();
        this.unsubscribeFilterBarListener = filterBarChangeSubscriber(this.$store, () => {
            this.fetchGuideViews();
        });
        this.fetchWorkflows();
        this.searchInput = '';
    },
    unmounted () {
        if (this.unsubscribeFilterBarListener) this.unsubscribeFilterBarListener();
    },
    methods: {
        getGuideLink,
        ...mapActions({
            updateGuide: 'guides/update',
            fetchGuides: 'guides/fetch',
            fetchGuideViews: 'guideAnalytics/fetchGuideViews',
            fetchWorkflows: 'workflows/fetch',
            patchGuide: 'guides/patch',
            updateUserSettingByName: 'userSettings/updateUserSettingByName'
        }),
        ...mapMutations({
            setUpdate: 'guides/setUpdate'
        }),
        handleColumnResize ($event) {
            const newUserSettings = onColumnResize($event, this.guidesListUserSettings);
            this.saveUserSettings(newUserSettings);
        },
        handleColumnChange ($event) {
            const newUserSettings = onColumnChange($event, this.guidesListUserSettings);
            this.saveUserSettings(newUserSettings);
        },
        handleSortChange ($event) {
            const newUserSettings = onSortChange($event, this.guidesListUserSettings);
            this.saveUserSettings(newUserSettings);
        },
        async saveUserSettings (newSettings) {
            await this.updateUserSettingByName({
                settingsName: this.guidesListUserSettingsName,
                value: newSettings
            });
        },
        async updateGuideStatus ({ guide, status }) {
            const { id: guideId } = guide;
            const state = status === 'scheduled' ? 'public' : status;
            const props = { state };

            await this.patchGuide({ guideId, props });
            this.clearGuideStatusUpdateData();
        },
        clearGuideStatusUpdateData () {
            this.guidestatusupdate = {
                guide: null,
                status: ''
            };
        },
        setGuideStatusUpdateData ({ guide, status }) {
            this.guidestatusupdate = {
                guide,
                status
            };
        },
        belongsToActiveApp (guide) {
            const { activeApp } = this;
            const { appId, appIds = [] } = guide;
            const isAppId = appId === activeApp.id;
            const isInAppIds = appIds.includes(activeApp.id);

            return isAppId || isInAppIds;
        },
        getApplicationNameFormatted (row) {
            return commaSeparatedAppList(row.apps);
        },
        activationToHuman (guide) {
            const activationMethods = guide.launchMethod.split('-').sort();
            const isConfirmation = isConfirmationGuide(guide);
            const isMobileGuide = isMobile(this.appFromGuide(guide));

            if (!guide.launchMethod) return '';

            if (guide.launchMethod === 'page-auto' && isMobileGuide) {
                return 'Automatically';
            }

            return activationMethods
                .map((method) => {
                    return {
                        api: this.activeIsTrainingSubscription && !isMobileGuide ? 'Launched via API' : 'No Activation',
                        auto: isMobileGuide ? 'App Launch' : 'Automatically',
                        badge: isConfirmation ? 'Confirmation' : 'Badge',
                        dom: 'Target Element',
                        embed: 'Embedded',
                        feature: 'Target Element',
                        launcher: 'Resource Center',
                        page: 'Page View'
                    }[method];
                })
                .join(', ');
        },
        getRowClassName ({ row }) {
            const { segmentSummary } = row;
            if (segmentSummary && segmentSummary.startsWith('Error')) {
                return 'entity-missing';
            }

            return '';
        },
        segmentSummary (guide) {
            const segmentId = get(guide, 'audienceUiHint.filters[0].segmentId');
            if (!segmentId) return 'Everyone';

            const segment = this.segmentsMap[segmentId];

            if (!segment) {
                return getMissingEntityText('segment', segmentId);
            }

            return getInlineSegmentSummary(
                segment,
                this.guideMap,
                this.pageMap,
                this.featureMap,
                this.workflowMap,
                this.segmentsMap,
                this.appsMap // apps map does not follow same id pattern
            );
        },
        updateSearch: debounce(function (val) {
            this.searchInput = val;
        }, 300),
        onSelectRowChange ({ selectedRows }) {
            this.selectedTableRows = selectedRows;
        },
        onSelectRowClear () {
            this.selectedTableRows = [];
        },
        openGuideCopyDrawerWithSingleGuide (guide) {
            this.useSingleGuideCopy = true;
            this.guide = guide;
            this.openGuideCopyDrawer();
        },
        closeCrossAppGuideDrawer (clearSelected) {
            if (clearSelected) this.$refs.guideListTable.clearSelected();
            this.showCrossGuideCreateDrawer = false;
        },
        async createCrossAppGuide ({ name, rows, urls, updatePolls }) {
            try {
                await updateGuideStatusesForCrossApp(rows, this.setUpdate);
            } catch (err) {
                this.errorOnUpdateGuideStatusesForCrossApp = err;
            }

            try {
                const guide = await createEmptyGuide({
                    name,
                    priority: this.maxPriority + 1,
                    isTraining: this.activeIsTrainingSubscription
                });

                await createCrossAppGuide({ guide, rows, urls, updatePolls });
                await this.updateGuide({ guide, setActive: true });
                this.$router.push({ name: 'guideSettings', params: { id: guide.id } }).catch(() => {});
            } catch (err) {
                this.errorOnCreateCrossAppGuide = err;
            }
        },
        closeBulkCopyDrawer () {
            this.useSingleGuideCopy = false;
            this.showBulkCopyDrawer = false;
        },
        onBulkCopyClose () {
            this.$refs.guideListTable.clearSelected();
        },
        async onBulkCopyOpen ({ pages, features }) {
            this.loadingSourceCountables = true;

            await loadSourceCountables({ pages, features });

            this.loadingSourceCountables = false;
        },
        openGuideCopyDrawer () {
            this.showBulkCopyDrawer = true;
        },
        openCrossAppGuideDrawer () {
            this.showCrossGuideCreateDrawer = true;
        },
        editorTypeToHuman (guide) {
            switch (guide.editorType) {
                case '':
                case EDITOR_TYPES.ADOPT_UI:
                case EDITOR_TYPES.ADOPT_STUDIO:
                    return 'Adopt Studio';
                case EDITOR_TYPES.ENGAGE_UI:
                case EDITOR_TYPES.VDS:
                    return 'VDS';
                default:
                    return '-';
            }
        },
        selectedAndTotalGuidesCount () {
            const totalGuidesLength = get(this.$refs, 'guideListTable.tableData.length', this.list.length);

            return this.selectedTableRows.length
                ? `${this.selectedTableRows.length} of ${totalGuidesLength} Selected`
                : totalGuidesLength;
        },
        canSelectGuide (guide) {
            return (
                !isMobile(this.appFromGuide(guide)) &&
                !isCrossApp(guide) &&
                (isCrossAppGuideEligible(guide) || canCopyGuide(guide))
            );
        },
        isCrossApp (guide) {
            return isCrossApp(guide);
        },
        openLaunchPreviewModal ($event) {
            this.launchPreviewModalProps = $event;
            this.showLaunchPreviewModal = true;
        },
        closeLaunchPreviewModal () {
            this.showLaunchPreviewModal = false;
            this.launchPreviewModalProps = {};
        },
        openDeleteGuideModal ($event) {
            this.deleteGuideModalProps = $event;
            this.showDeleteGuideModal = true;
        },
        closeDeleteGuideModal () {
            this.showDeleteGuideModal = false;
            this.deleteGuideModalProps = {};
        }
    }
};
</script>

<style lang="scss">
.guide-list {
    .filters {
        width: 100%;
    }
}

.guide-list-table {
    .entity-missing {
        color: $red-error;
        font-weight: bold;
    }

    &--empty {
        height: 350px;
    }

    .pendo-table__cell {
        word-break: unset;
    }

    &--table-header-left {
        align-items: center;
        display: flex;
        gap: 8px;
    }

    &--active-count {
        font-size: 18px;
        color: $gray-lighter-2;
        font-variant-numeric: tabular-nums;
    }
}

.editor-type-cell {
    display: flex;
    align-items: center;

    i {
        margin-right: 1em;
    }
}

.app-name-cell {
    text-transform: capitalize;
}

.guide-views-column-header {
    display: flex;

    &--icon {
        padding: 0 0.5em;
        display: flex;
    }
}
</style>
